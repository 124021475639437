import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-end gap-4">
        {/* <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
              Premium{" "}
              <Link to="https://www.bootstrapdash.com/" target="_blank">
                Bootstrap admin template
              </Link>{" "}
              from BootstrapDash.
            </span> */}
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Copyright © 2024. All rights reserved.
        </span>
        <div
          style={{ textAlign: "center", color: "black" }}
          className="mt-1 mt-sm-0"
        >
          Engineered by
          <a
            href="https://vyqda.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "none" }}
          >
            <span> Vyqda </span>
          </a>
          Excellence
        </div>
      </div>
    </footer>
  );
}

export default Footer;
