import { useSelector } from "react-redux";

// Get today's date in YYYY-MM-DD format

// Custom hook for getting the first and last day of the financial year
export const useFinancialYear = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);

  const today = new Date();
  const currentYear = today.getFullYear();

  // Use FYear if available, otherwise default to the current year
  const financialYear =
    getFullYearFromTwoDigits(Number(FYear?.value)) || currentYear;

  // Construct the first and last day of the financial year
  const firstDayOfYear = `${financialYear}-04-01`;
  const lastDayOfYear = `${financialYear + 1}-03-31`;
  const effectiveDate = new Date().toISOString().split("T")[0];

  const isTodayInFinancialYear =
    effectiveDate >= firstDayOfYear && effectiveDate <= lastDayOfYear;

  const todayDate = isTodayInFinancialYear ? effectiveDate : lastDayOfYear;

  return { firstDayOfYear, lastDayOfYear, todayDate };
};

function getFullYearFromTwoDigits(twoDigitYear) {
  // Get the current year to determine the century
  const currentYear = new Date().getFullYear();
  const currentCentury = Math.floor(currentYear / 100) * 100;

  // Convert the two-digit input to a number
  const year = Number(twoDigitYear);

  // Determine the full year by combining with the current century
  const fullYear = year + currentCentury;

  // If the two-digit year is greater than the current last two digits,
  // it means it's from the previous century, adjust accordingly
  return fullYear <= currentYear ? fullYear : fullYear - 100;
}
