import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../Items/ProductServices/ProductServices";
import { useSelector } from "react-redux";
import CustomPagination from "../../Pagination/CustomPagination";
import usePagination from "../../Pagination/Pagination";
import Spinner from "../../spinner/first_spinner/Spinner";
import {
  tableStyle,
  tdThStyle,
} from "../../../components/Pagination/tableStyle";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import NotFoundWithImage from "../../notFound/NotFoundWithImage/NotFoundWithImage";

const StockReports = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["product"],
    queryFn: fetchProducts,
  });

  const _DATA = usePagination(data?.products || []);

  const handleChange = (e, p) => {
    _DATA.jump(p);
  };

  const handleOnChangePerPage = (e) => {
    const { value } = e.target;
    _DATA.showPerPage(value);
  };

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  if (isLoading) {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <SecondSpinner />
        </div>
      </div>
    );
  }

  if (isError) return <NotFoundWithImage />;

  return (
    <>
      <div className="container text-center m-4 mx-auto"></div>
      <div className="table-responsive mt-4">
        <label className="mb-3">
          Show{" "}
          <select
            name="example_length"
            aria-controls="example"
            className=""
            value={_DATA.perPage}
            onChange={handleOnChangePerPage}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={_DATA.length}>All</option>
          </select>{" "}
          entries
        </label>
        <div style={{ overflowX: "auto", scrollbarWidth: "thin" }}>
          <table
            className="table align-items-center table-flush mb-3"
            id="stock_table"
            style={{ ...tableStyle }}
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  colSpan={1}
                  style={{ ...tdThStyle, width: "70px" }}
                >
                  Sr. No.
                </th>
                <th
                  scope="col"
                  colSpan={10}
                  style={{ ...tdThStyle, width: "180px" }}
                >
                  Name
                </th>
                <th scope="col" style={{ ...tdThStyle, width: "90px" }}>
                  Type
                </th>
                <th scope="col" style={{ ...tdThStyle, width: "120px" }}>
                  Category
                </th>
                <th scope="col" style={{ ...tdThStyle, width: "120px" }}>
                  Brand
                </th>
                <th scope="col" style={{ ...tdThStyle, width: "92px" }}>
                  Qty
                </th>

                <th scope="col" style={{ ...tdThStyle, width: "100px" }}>
                  Sale Price
                </th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((prod, idx) => (
                <tr key={idx}>
                  <td colSpan={1} style={{ ...tdThStyle, width: "70px" }}>
                    {(_DATA.currentPage - 1) * _DATA.perPage + (idx + 1)}
                  </td>
                  <td colSpan={10} style={{ ...tdThStyle, width: "180px" }}>
                    {prod.name}
                  </td>
                  <td style={{ ...tdThStyle, width: "90px" }}>{prod.type}</td>
                  <td style={{ ...tdThStyle, width: "120px" }}>
                    {prod.categoryName || "--"}
                  </td>
                  <td style={{ ...tdThStyle, width: "120px" }}>
                    {prod.brandName || "--"}
                  </td>
                  <td style={{ ...tdThStyle, width: "92px" }}>
                    <b>
                      <span
                        className={
                          prod.opening_qty_per < 1
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {prod.opening_qty_per}
                      </span>
                    </b>
                  </td>

                  <td style={{ ...tdThStyle, width: "100px" }}>
                    ₹ {prod.s_price || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <CustomPagination
          count={_DATA.maxPage}
          page={_DATA.currentPage}
          length={_DATA.length}
          currentDataLength={_DATA.currentData().length}
          onChange={handleChange}
          perPage={_DATA.perPage}
        />
      </div>
    </>
  );
};

export default StockReports;
