import React from "react";
import { Link } from "react-router-dom";
import { formatNumberWithIndianCommas } from "../../utils/helpers";
const Overview = ({ data }) => {
  return (
    <div className="row">
      <div className="col-xl-3 col-lg-6 col-sm-6">
        <Link to="/invoices/sales" className="nav-link px-0">
          <div className="card card-stats  mb-xl-0">
            <div className="card-body d-flex p-3">
              <div className="col  gap-2 d-flex flex-column">
                <h5 className="card-title text-muted mb-0">Received</h5>
                <span className="h2 text-success font-weight-bold mb-0">
                  {formatNumberWithIndianCommas(
                    data?.sales?.totalPaidAmount || 0
                  )}
                </span>
              </div>
              <div className="col-auto">
                <div
                  className="icon icon-shape bg-danger text-white rounded-circle shadow"
                  style={{
                    height: "36px",
                    width: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className="fa fa-get-pocket"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
              {/* <p className="mt-3 mb-0 text-muted text-sm d-sm-flex gap-4">
                <span className="font-weight-bold text-success mr-2">
                  ₹ {data?.sales?.totalPaidAmount || 0}
                </span>
                <span className="font-weight-bold text-danger ml-3">
                  ₹ {data?.sales?.totalBalance || 0}
                </span>
              </p> */}
            </div>
          </div>
        </Link>
      </div>
      <div className="col-xl-3 col-lg-6 col-sm-6">
        <Link to="/invoices/sales" className="nav-link px-0">
          <div className="card card-stats  mb-xl-0">
            <div className="card-body d-flex p-3">
              <div className="col  gap-2 d-flex flex-column">
                <h5 className="card-title  text-muted mb-0">Due Date Pass</h5>
                <span className="h2 text-danger font-weight-bold mb-0">
                  {formatNumberWithIndianCommas(
                    data?.sales?.totalDuesPassBalance || 0
                  )}
                </span>
              </div>
              <div className="col-auto">
                <div
                  className="icon icon-shape bg-danger text-white rounded-circle shadow"
                  style={{
                    height: "36px",
                    width: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className="fa fa-bar-chart"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
              {/* <p className="mt-3 mb-0 text-muted text-sm d-sm-flex gap-4">
                <span className="font-weight-bold text-success mr-2">
                  ₹ {data?.sales?.totalPaidAmount || 0}
                </span>
                <span className="font-weight-bold text-danger ml-3">
                  ₹ {data?.sales?.totalBalance || 0}
                </span>
              </p> */}
            </div>
          </div>
        </Link>
      </div>
      <div className="col-xl-3 col-lg-6 col-sm-6">
        <Link to="/invoices/expenses" className="nav-link px-0">
          <div className="card card-stats  mb-xl-0">
            <div className="card-body d-flex p-3">
              <div className="col  gap-2 d-flex flex-column">
                <h5 className="card-title  text-muted mb-0">Expenses</h5>
                <span className="h2 font-weight-bold mb-0">
                  {formatNumberWithIndianCommas(
                    data?.purchase?.totalAmount || 0
                  )}
                </span>
              </div>
              <div className="col-auto">
                <div
                  className="icon icon-shape bg-danger text-white rounded-circle shadow"
                  style={{
                    height: "36px",
                    width: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i className="	fa fa-rupee" style={{ fontSize: "16px" }}></i>
                </div>
              </div>
              {/* <p className="mt-3 mb-0 text-muted text-sm d-sm-flex gap-4">
                <span className="font-weight-bold text-success mr-2">
                  ₹ {data?.purchase?.totalPaidAmount || 0}
                </span>
                <span className="font-weight-bold text-danger ml-3">
                  ₹ {data?.purchase?.totalBalance || 0}
                </span>
              </p> */}
            </div>
          </div>
        </Link>
      </div>
      <div className="col-xl-3 col-lg-6 col-sm-6">
        <Link to="/invoices/sales" className="nav-link px-0">
          <div className="card card-stats  xl-0">
            <div className="card-body d-flex p-3">
              <div className="col  gap-2 d-flex flex-column">
                <h5 className="card-title  text-muted mb-0">
                  <span className="text-success">Profit</span> /{" "}
                  <span className="text-danger">Loss</span>
                </h5>
                <span
                  className={`h2 ${
                    Number(
                      data?.sales?.totalAmount - data?.purchase?.totalAmount
                    ) >= 0
                      ? "text-success"
                      : "text-danger"
                  } font-weight-bold mb-0`}
                >
                  {formatNumberWithIndianCommas(
                    data?.sales?.totalAmount - data?.purchase?.totalAmount || 0
                  )}
                </span>
              </div>
              <div className="col-auto">
                <div
                  className="icon icon-shape bg-danger text-white rounded-circle shadow"
                  style={{
                    height: "36px",
                    width: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i className="fa fa-money" style={{ fontSize: "16px" }}></i>
                </div>
              </div>
              {/* <p className="mt-3 mb-0 text-muted text-sm d-sm-flex gap-4">
                <span className="font-weight-bold text-success mr-2">
                  <i
                    className="fa fa-arrow-up me-sm-2"
                    style={{ fontSize: "15px" }}
                  ></i>
                  0 %
                </span>
                Since Last Week
              </p> */}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Overview;
