import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { editFinancialYear } from "../redux-store/FinancialYear";
import Select from "react-select";
import AppLogo from "../assets/AppLogo";
import { setLogout } from "../redux-store/Auth";
import { hasMultiplePermissions, hasPermission } from "../utils/helpers";

function Header() {
  const dispatch = useDispatch();
  const FYState = useSelector((state) => state.FinancialYear);
  const { loggedUser } = useSelector((state) => state.Auth);

  const toggleOffcanvas = () => {
    document.getElementById("sidebar")?.classList.toggle("active");
  };

  const greet = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "Good morning";
    if (currentHour < 18) return "Good afternoon";
    return "Good evening";
  };

  return (
    <nav
      className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row"
      style={{ zIndex: 50 }}
    >
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center p-0">
        <Link to="/">
          <AppLogo />
        </Link>
      </div>
      <div
        className="navbar-menu-wrapper d-flex align-items-top"
        style={{ padding: 0, paddingRight: "1rem" }}
      >
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
            <h1 className="welcome-text">
              {greet()}{" "}
              <span className="text-black fw-bold">
                {loggedUser?.type === 1 ? "Admin" : loggedUser.name}
              </span>
            </h1>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto navbar-gap gap-3">
          <li className="nav-item dropdown  d-lg-block m-0">
            <Select
              name="category"
              className="w-100"
              value={FYState.FYear}
              placeholder="Select FY year"
              onChange={(val) => dispatch(editFinancialYear(val))}
              options={FYState.FinancialYearArray}
            />
          </li>
          <li className="nav-item dropdown  d-lg-block user-dropdown">
            <span
              className="nav-link  m-0"
              id="UserDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt="My Profile"
              />
            </span>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <button
                type="button"
                className="close-btn"
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  background: "red",
                  border: "none",
                  cursor: "pointer",
                  fontSize: 14,
                  color: "white",
                  borderRadius: 5,
                }}
              >
                X
              </button>
              <div className="dropdown-header text-center">
                <p className="mb-1 mt-3 font-weight-semibold">Welcome !</p>
              </div>
              <Link className="dropdown-item" to="/profile">
                <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2" />
                My Profile
              </Link>
              {hasPermission(loggedUser, 58) && (
                <Link className="dropdown-item" to="/mysubscription">
                  <i className="dropdown-item-icon mdi mdi-coin text-primary me-2" />
                  My Subscription
                </Link>
              )}
              {hasMultiplePermissions(loggedUser, [47, 48, 49]) && (
                <Link className="dropdown-item" to="/banks">
                  <i className="dropdown-item-icon mdi mdi-bank text-primary me-2" />
                  Bank Details
                </Link>
              )}
              {hasMultiplePermissions(loggedUser, [4, 9, 14]) && (
                <Link className="dropdown-item" to="/import/data">
                  <i className="dropdown-item-icon mdi mdi-clipboard-arrow-down text-primary me-2" />
                  Import data
                </Link>
              )}
              {hasPermission(loggedUser, 50) && (
                <Link className="dropdown-item" to="/pdf/select">
                  <i className="dropdown-item-icon mdi mdi-shredder text-primary me-2" />
                  Print Layout Configurations
                </Link>
              )}
              {hasPermission(loggedUser, 51) && (
                <Link className="dropdown-item" to="/mail/select">
                  <i className="dropdown-item-icon mdi mdi-email text-primary me-2" />
                  Mail Permissions
                </Link>
              )}
              {hasPermission(loggedUser, 52) && (
                <Link className="dropdown-item" to="/manage_company">
                  <i className="dropdown-item-icon mdi mdi-factory text-primary me-2" />
                  Manage Company
                </Link>
              )}
              {hasPermission(loggedUser, 53) && (
                <Link className="dropdown-item" to="/settings">
                  <i className="dropdown-item-icon mdi mdi-settings text-primary me-2" />
                  Settings
                </Link>
              )}
              <Link
                onClick={() => dispatch(setLogout())}
                to="/auth/login"
                className="dropdown-item"
              >
                <i className="dropdown-item-icon mdi mdi-power text-primary me-2" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Header;
