import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
const SalesBarChart = ({ monthlyExpenseArray, monthlySalesArray }) => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );

  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sales",
        backgroundColor: documentStyle.getPropertyValue("--pink-500"),
        borderColor: documentStyle.getPropertyValue("--pink-500"),
        data: monthlySalesArray,
      },
      {
        label: "Expenses",
        backgroundColor: documentStyle.getPropertyValue("--blue-500"),
        borderColor: documentStyle.getPropertyValue("--blue-500"),
        data: monthlyExpenseArray,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          fontColor: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
          callback: function (value) {
            return new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0, // Or set to 2 if you want decimals
              maximumFractionDigits: 2,
            }).format(value);
          },
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div style={{ minWidth: "40vw", overflowX: "auto" }}>
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default SalesBarChart;
