import React, { useEffect, useMemo, useState } from "react";
import { selectStyle } from "../../../assets/selectCustomStyle";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { expensePaymentApi, payApi, paymentApi } from "../../../assets/apis";
import Spinner from "../../../spinner/first_spinner/Spinner";
import ResultNotFound from "../../../notFound/ResultNotFound/ResultNotFound";
import { todayDate, useFinancialYear } from "../../../assets/Date";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { dateFormateDDMMYYYY } from "../../../utils/helpers";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import CoverSpin from "../../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../../spinner/third_spinner/SecondSpinner";
import NotFoundWithImage from "../../../notFound/NotFoundWithImage/NotFoundWithImage";
const ExpensePay = () => {
  const navigate = useNavigate();
  const { companyData } = useSelector((state) => state.Company);
  const { firstDayOfYear, lastDayOfYear, todayDate } = useFinancialYear();
  const { FYear } = useSelector((store) => store.FinancialYear);

  const [expenseInvoice, setExpenseInvoice] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [apiInProccess, setApiInProccess] = useState(false);
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const [addPayment, setAddPayment] = useState({
    date: todayDate,
    method: "Cash",
    note: "",
  });
  const { id } = useParams();
  const { data, isError, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [id, FYear.value],
    queryFn: () =>
      axios
        .get(`${payApi}/invoice/expense/${id}`)
        .then((response) => response.data),
    retry: false,
  });

  useEffect(() => {
    setAddPayment((pre) => ({ ...pre, date: todayDate }));
  }, [FYear.value]);

  useMemo(() => {
    setExpenseInvoice(data?.expenseInvoice || {});
    setPaymentDetails(data?.paymentDetails || []);
    if (data?.expenseInvoice) {
      const { name, invoice, balance } = data?.expenseInvoice;
      setAddPayment((pre) => ({
        ...pre,
        name,
        invoice_no: invoice,
        amount: balance,
        pay: balance,
      }));
    }
  }, [data]);

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };

  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };
  const handleDelete = async (id) => {
    try {
      const result = await axios.delete(`${paymentApi}/expense/payment/${id}`);
      const response = result.data;
      refetch();
      toast.success(response?.message || "Payment record deleted successfully");
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      toast.error(error?.response?.data?.message || "Internal Server Error");
    } finally {
      handleClosePortal();
    }
  };

  const onHandleInputChange = (e) => {
    setAddPayment((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddExpenseInputChangeWithConditonTypeFloat = (
    e,
    validationRegex
  ) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddPayment((prev) => ({ ...prev, [name]: value }));
    }
  };

  //create new payment logic
  const savePayment = async (e) => {
    e.preventDefault();
    if (
      !parseFloat(addPayment.pay) ||
      parseFloat(addPayment.amount) < parseFloat(addPayment.pay)
    ) {
      return toast.error("You can not pay more than invoiced amount");
    } else if (!addPayment.date) {
      return toast.error("Please fill all required fields");
    }
    const payload = {
      name: addPayment.name,
      invoice_no: addPayment.invoice_no,
      invoice_amount: parseFloat(addPayment.amount),
      paid_amount: parseFloat(addPayment.pay),
      method: addPayment.method,
      date: addPayment.date,
      note: addPayment.note,
      type: 1,
    };
    setApiInProccess(true);

    try {
      const response = await axios.post(expensePaymentApi, payload);

      if (response.status === 200) {
        toast.success("Payment added successfully");
        refetch();
        setAddPayment({ date: todayDate, method: "Cash", note: "" });
      } else {
        if (error?.response?.status === 403) return navigate("/upgrade");

        toast.error("Failed to add payment. Please try again.");
      }
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");

      console.error(error);
      toast.error("An error occurred while adding payment");
    } finally {
      setApiInProccess(false);
    }
  };

  useEffect(() => {
    if (isError && error?.response?.status === 403) {
      navigate("/upgrade");
    }
  }, [isError, error, navigate]);

  if (isLoading || isFetching) {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <SecondSpinner />
        </div>
      </div>
    );
  }

  if (isError) return <NotFoundWithImage />;

  return (
    <div className="main-panel">
      <div className="content-wrapper  ">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title"> Payment</div>
            <div className="d-flex gap-1">
              <button
                className="btn btn-warning d-flex gap-1 justify-content-center align-items-center px-2 py-1"
                onClick={() => navigate(-1)}
              >
                <i className="mdi mdi mdi-keyboard-backspace"></i>Go Back
              </button>
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() =>
                  navigate(`/invoices/view/expenses/${expenseInvoice.invoice}`)
                }
              >
                View Expense
              </button>
            </div>
          </div>

          <h6 className="heading-small text-muted mb-4">
            Payment For{" "}
            {companyData?.purchase_inv_prefix
              ? `${companyData.purchase_inv_prefix}${expenseInvoice.invoice}`
              : `EXP-${expenseInvoice?.invoice}`}
          </h6>
          {expenseInvoice?.balance ? (
            <form onSubmit={savePayment}>
              <div className="pl-lg-1">
                <div className="pl-lg-1">
                  <div className="row">
                    <div className="col-lg-2">
                      <div className="form-group focused">
                        <label
                          className="form-control-label"
                          htmlFor="id_payment_method"
                        >
                          Payment Method *
                        </label>

                        <select
                          name="method"
                          className="form-control  "
                          id="id_payment_method"
                          value={addPayment.method || ""}
                          style={selectStyle}
                          onChange={onHandleInputChange}
                          required
                        >
                          <option value="Cash">Cash</option>
                          <option value="UPI">UPI</option>

                          <option value="Credit Card">Credit Card</option>

                          <option value="Cheque">Cheque</option>

                          <option value="Bank Transfer">Bank Transfer</option>

                          <option value="Pay Slip">Pay Slip</option>

                          <option value="EMI">EMI</option>

                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="form-group focused">
                        <label
                          className="form-control-label"
                          htmlFor="outStanding_amount"
                        >
                          Remaining *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="amount"
                          value={addPayment.amount || ""}
                          onChange={onHandleInputChange}
                          readOnly={true}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="form-group">
                        <label
                          className="form-control-label"
                          htmlFor="pay_amount"
                        >
                          Pay *
                        </label>
                        <input
                          type="test"
                          placeholder="amount"
                          name="pay"
                          id="pay_amount"
                          className="form-control"
                          value={addPayment.pay || ""}
                          onChange={(e) =>
                            handleAddExpenseInputChangeWithConditonTypeFloat(
                              e,
                              /^\d*\.?\d{0,2}$/
                            )
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="form-group focused">
                        <label
                          className="form-control-label"
                          htmlFor="payment_data"
                        >
                          Payment Date *
                        </label>
                        <input
                          type="date"
                          id="payment_data"
                          placeholder="date"
                          min={firstDayOfYear}
                          max={todayDate}
                          name="date"
                          className="form-control"
                          value={addPayment.date || ""}
                          onChange={onHandleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-group">
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Notes
                        </label>

                        <input
                          type="text"
                          placeholder="note"
                          name="note"
                          value={addPayment.note || ""}
                          onChange={onHandleInputChange}
                          className="form-control form-control-alternative "
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-1"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary mt-4"
                          style={{ padding: "14px" }}
                          disabled={apiInProccess}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            ""
          )}

          <h6 className="heading-small text-muted mt-4">History</h6>
          {/* Amount Table */}
          <table className="table table-bordered">
            <thead>
              <tr width="5%">
                <th width="2%">
                  <font size="2px" className="text-primary">
                    Total
                  </font>
                </th>
                <th width="2%">
                  <font size="2px" className="text-primary">
                    <b>₹ {expenseInvoice?.total || 0}</b>
                  </font>
                </th>
              </tr>
              <tr width="5%">
                <th width="2%">
                  <font size="2px" color="green">
                    Paid
                  </font>
                </th>
                <th width="2%">
                  <font size="2px" color="green">
                    <b>₹ {expenseInvoice?.paid_amount || 0}</b>
                  </font>
                </th>
              </tr>
              <tr width="5%">
                <th width="2%">
                  <font size="2px" color="red">
                    Remaining
                  </font>
                </th>
                <th width="2%">
                  <font size="2px" color="red">
                    <b>₹ {expenseInvoice?.balance || 0}</b>
                  </font>
                </th>
              </tr>
            </thead>
          </table>

          {/* Pay Record Table */}
          <div className="table-responsive mt-4 mb-8">
            <table
              className="table align-items-center table-flush"
              id="payment_table"
            >
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Method</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col">Notes</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {paymentDetails?.length ? (
                  paymentDetails.map((payment, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{payment?.method}</td>
                      <td>₹ {payment?.paid_amount}</td>
                      <td>{dateFormateDDMMYYYY(payment?.date)}</td>
                      {/* <td>Canceled</td> */}
                      <td>{payment?.note || "--"}</td>

                      <td>
                        <i
                          style={{ cursor: "pointer" }}
                          className="dropdown-item-icon mdi mdi-delete-forever mdi-18px text-primary me-2 hover-cursor"
                          title="Delete"
                          onClick={() =>
                            handleOpenPortal({ ...payment, index: index + 1 })
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
            {!paymentDetails.length && (
              <ResultNotFound
                message={`Sorry, we counldn't find any results`}
              />
            )}
          </div>

          {openPortal && (
            <ConfirmPortal
              onClose={handleClosePortal}
              type={"Payment Record"}
              name={`Payment Record #${deletingItem.index}`}
              id={deletingItem.id}
              handleDalete={handleDelete}
            ></ConfirmPortal>
          )}

          {/* <!-- Modal --> */}
          {/* <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Delete Payment
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  Do you want to delete payment record No.
                  <b>
                    <span id="modal_body"></span>
                  </b>
                  ?
                </div>
                <div className="modal-footer">
                  <a
                    href=""
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    Cancel
                  </a>
                  <a
                    href=""
                    id="delete_button"
                    className="btn btn-primary btn-sm"
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div> */}

          {/* <!-- Cancel Modal --> */}
          {/* <div
            className="modal fade"
            id="cancelModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Cancel Payment
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  Do you want to Cancel Payment{" "}
                  <b>
                    <span id="cancel_body"></span>
                  </b>
                  ?
                </div>
                <div className="modal-footer">
                  <a
                    href=""
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                  >
                    No
                  </a>
                  <a
                    href=""
                    id="cancel_button"
                    className="btn btn-primary btn-sm"
                  >
                    Yes
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ExpensePay;
