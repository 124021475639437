import React from "react";
import NoImage from "../../assets/images/no_data_found_img.png";
const NotFoundWithImage = () => {
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={NoImage}
            alt=""
            className="not_found_image"
            height={"auto"}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundWithImage;
