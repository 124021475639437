import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { gsthsnApi } from "../../../assets/apis";
import Spinner from "../../../spinner/first_spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import CoverSpin from "../../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../../spinner/third_spinner/SecondSpinner";
import NotFoundWithImage from "../../../notFound/NotFoundWithImage/NotFoundWithImage";
const EditGstHsn = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const dispatch = useDispatch();
  const [editedHsnData, setEditedHsnData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isError, isLoading, error } = useQuery({
    queryKey: [id, FYear.value],
    queryFn: () =>
      axios.get(`${gsthsnApi}/${id}`).then((response) => response.data.data),
    retry: false,
  });

  useEffect(() => {
    if (data) {
      const { createdAt, updatedAt, companyId, id, ...rest } = data;
      setEditedHsnData(rest);
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedHsnData((pre) => ({
      ...pre,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleInputChangeWithValidation = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setEditedHsnData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setEditedHsnData((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name_of_commodity } = editedHsnData;
    let updatedHsnData = {
      ...editedHsnData,
      ...(name_of_commodity && { name_of_commodity: name_of_commodity.trim() }),
    };

    setEditedHsnData(updatedHsnData);
    if (!updatedHsnData.name_of_commodity)
      return toast.error("Please enter name of commodity");
    if (+updatedHsnData.gst_rate > 100)
      return toast.error("Please enter a valid rate");

    try {
      await axios.put(`${gsthsnApi}/${id}`, updatedHsnData);
      toast.success("HSN saved successfully");
      navigate("/gsthsn");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Taxes" }));
    dispatch(setSiderBarActiveLink({ link: "gsthsn" }));
  }, []);

  if (isLoading) {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <SecondSpinner />
        </div>
      </div>
    );
  }

  if (isError) return <NotFoundWithImage />;
  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Edit HSN</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/gsthsn")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="col-lg-12 ">
                        <h5>HSN INFORMATION *</h5>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 mt-2">
                            <label>Hsn Code No. *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="HSN Code No."
                              name="hsn_code_no"
                              required
                              minLength={4}
                              maxLength={8}
                              value={editedHsnData.hsn_code_no || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Hsn Code *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="HSN Code"
                              name="hsn_code"
                              required
                              minLength={4}
                              maxLength={8}
                              value={editedHsnData.hsn_code || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Name of Commodity *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Commodity"
                              name="name_of_commodity"
                              required
                              value={editedHsnData.name_of_commodity || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[\s\S]*$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Gst Rate (%) *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="GST Rate"
                              name="gst_rate"
                              required
                              minLength={1}
                              maxLength={3}
                              value={editedHsnData.gst_rate}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-12 mt-2">
                            <label>Active ? </label>{" "}
                            <input
                              type="checkbox"
                              name="active"
                              checked={editedHsnData.active || ""}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-9 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex justify-content-center px-3 py-2"
                          >
                            <i className="mdi mdi-content-save me-1"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGstHsn;
