import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../redux-store/sidebarActiveList";
import { setLogout } from "../redux-store/Auth";
import { hasMultiplePermissions, hasPermission } from "../utils/helpers";
// import style from "./Sidebar.module.css"

function Sidebar() {
  const { loggedUser } = useSelector((state) => state.Auth);
  const { openedList, activeLink } = useSelector(
    (state) => state.SidebarActiveList
  );

  const dispatch = useDispatch();

  const handleOpenModel = (key) => {
    dispatch(setSiderBarActiveList({ name: key }));
  };
  const handleActiveLink = (linkName) => {
    dispatch(setSiderBarActiveLink({ link: linkName }));
  };

  const closeSidebar = () => {
    document.getElementById("sidebar")?.classList.remove("active");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.getElementById("sidebar");
      const toggleButton = document.querySelector(".navbar-toggler");

      // If the click is outside the sidebar and not on the toggle button
      if (
        sidebar &&
        !sidebar.contains(event.target) &&
        !toggleButton.contains(event.target)
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ background: "#f4f5f7" }}>
        <div className="theme-setting-wrapper">
          {/* <div id="settings-trigger">
            <i className="ti-settings" />
          </div> */}
          <div id="theme-settings" className="settings-panel">
            <i className="settings-close ti-close" />
            <p className="settings-heading">SIDEBAR SKINS</p>
            <div
              className="sidebar-bg-options selected"
              id="sidebar-light-theme"
            >
              <div className="img-ss rounded-circle bg-light border me-3" />
              Light
            </div>
            <div className="sidebar-bg-options" id="sidebar-dark-theme">
              <div className="img-ss rounded-circle bg-dark border me-3" />
              Dark
            </div>
            <p className="settings-heading mt-2">HEADER SKINS</p>
            <div className="color-tiles mx-0 px-4">
              <div className="tiles success" />
              <div className="tiles warning" />
              <div className="tiles danger" />
              <div className="tiles info" />
              <div className="tiles dark" />
              <div className="tiles default" />
            </div>
          </div>
        </div>
        <div id="right-sidebar" className="settings-panel">
          <i className="settings-close ti-close" />
          <ul
            className="nav nav-tabs border-top"
            id="setting-panel"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="todo-tab"
                data-bs-toggle="tab"
                href="#todo-section"
                role="tab"
                aria-controls="todo-section"
                aria-expanded="true"
              >
                TO DO LIST
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="chats-tab"
                data-bs-toggle="tab"
                href="#chats-section"
                role="tab"
                aria-controls="chats-section"
              >
                CHATS
              </a>
            </li>
          </ul>
          <div className="tab-content" id="setting-content">
            <div
              className="tab-pane fade show active scroll-wrapper"
              id="todo-section"
              role="tabpanel"
              aria-labelledby="todo-section"
            >
              <div className="add-items d-flex px-3 mb-0">
                <form className="form w-100">
                  <div className="form-group d-flex">
                    <input
                      type="text"
                      className="form-control todo-list-input"
                      placeholder="Add To-do"
                    />
                    <button
                      type="submit"
                      className="add btn btn-primary todo-list-add-btn"
                      id="add-task"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
              <div className="list-wrapper px-3">
                <ul className="d-flex flex-column-reverse todo-list">
                  <li>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="checkbox" type="checkbox" />
                        Team review meeting at 3.00 PM
                      </label>
                    </div>
                    <i className="remove ti-close" />
                  </li>
                  <li>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="checkbox" type="checkbox" />
                        Prepare for presentation
                      </label>
                    </div>
                    <i className="remove ti-close" />
                  </li>
                  <li>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="checkbox" type="checkbox" />
                        Resolve all the low priority tickets due today
                      </label>
                    </div>
                    <i className="remove ti-close" />
                  </li>
                  <li className="completed">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="checkbox"
                          type="checkbox"
                          defaultChecked
                        />
                        Schedule meeting for next week
                      </label>
                    </div>
                    <i className="remove ti-close" />
                  </li>
                  <li className="completed">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="checkbox"
                          type="checkbox"
                          defaultChecked
                        />
                        Project review
                      </label>
                    </div>
                    <i className="remove ti-close" />
                  </li>
                </ul>
              </div>
              <h4 className="px-3 text-muted mt-5 fw-light mb-0">Events</h4>
              <div className="events pt-4 px-3">
                <div className="wrapper d-flex mb-2">
                  <i className="ti-control-record text-primary me-2" />
                  <span>Feb 11 2018</span>
                </div>
                <p className="mb-0 font-weight-thin text-gray">
                  Creating component page build a js
                </p>
                <p className="text-gray mb-0">The total number of sessions</p>
              </div>
              <div className="events pt-4 px-3">
                <div className="wrapper d-flex mb-2">
                  <i className="ti-control-record text-primary me-2" />
                  <span>Feb 7 2018</span>
                </div>
                <p className="mb-0 font-weight-thin text-gray">
                  Meeting with Alisa
                </p>
                <p className="text-gray mb-0 ">Call Sarah Graves</p>
              </div>
            </div>
            {/* To do section tab ends */}
            <div
              className="tab-pane fade"
              id="chats-section"
              role="tabpanel"
              aria-labelledby="chats-section"
            >
              <div className="d-flex align-items-center justify-content-between border-bottom">
                <p className="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">
                  Friends
                </p>
                <small className="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 fw-normal">
                  See All
                </small>
              </div>
              <ul className="chat-list">
                <li className="list active">
                  <div className="profile">
                    <img src="" alt="" />
                    <span className="online" />
                  </div>
                  <div className="info">
                    <p>Thomas Douglas</p>
                    <p>Available</p>
                  </div>
                  <small className="text-muted my-auto">19 min</small>
                </li>
                <li className="list">
                  <div className="profile">
                    <img src="" alt="" />
                    <span className="offline" />
                  </div>
                  <div className="info">
                    <div className="wrapper d-flex">
                      <p>Catherine</p>
                    </div>
                    <p>Away</p>
                  </div>
                  <div className="badge badge-success badge-pill my-auto mx-2">
                    4
                  </div>
                  <small className="text-muted my-auto">23 min</small>
                </li>
                <li className="list">
                  <div className="profile">
                    <img src="" alt="" />
                    <span className="online" />
                  </div>
                  <div className="info">
                    <p>Daniel Russell</p>
                    <p>Available</p>
                  </div>
                  <small className="text-muted my-auto">14 min</small>
                </li>
                <li className="list">
                  <div className="profile">
                    <img src="" alt="" />
                    <span className="offline" />
                  </div>
                  <div className="info">
                    <p>James Richardson</p>
                    <p>Away</p>
                  </div>
                  <small className="text-muted my-auto">2 min</small>
                </li>
                <li className="list">
                  <div className="profile">
                    <img src="" alt="" />
                    <span className="online" />
                  </div>
                  <div className="info">
                    <p>Madeline Kennedy</p>
                    <p>Available</p>
                  </div>
                  <small className="text-muted my-auto">5 min</small>
                </li>
                <li className="list">
                  <div className="profile">
                    <img src="" alt="" />
                    <span className="online" />
                  </div>
                  <div className="info">
                    <p>Sarah Graves</p>
                    <p>Available</p>
                  </div>
                  <small className="text-muted my-auto">47 min</small>
                </li>
              </ul>
            </div>
            {/* chat tab ends */}
          </div>
        </div>
        {/* partial */}
        {/* partial:partials/_sidebar.html */}
        <nav className={`sidebar sidebar-offcanvas`} id="sidebar">
          <ul
            className="nav"
            style={{
              overflowY: "auto",
              position: "fixed",
              height: "calc(100vh - 97px)",
              backgroundColor: "#f4f5f7",
              scrollbarWidth: "thin",
              width: "220px",
            }}
          >
            {hasPermission(loggedUser, 54) && (
              <li
                className={`nav-item ${activeLink === "dashboard" && "active"}`}
              >
                <Link
                  className="nav-link"
                  to="/"
                  onClick={closeSidebar}
                  // onClick={() => handleActiveLink("dashboard")}
                >
                  <i className="menu-icon mdi mdi-airplay" />
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
            )}
            {hasMultiplePermissions(
              loggedUser,
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            ) && (
              <li className="nav-item">
                <div
                  className={`nav-link ${
                    openedList === "Contacts" && "collapsed"
                  }`}
                  data-bs-toggle="collapse"
                  aria-expanded={openedList === "Contacts"}
                  aria-controls="form-elements"
                  onClick={() =>
                    handleOpenModel(
                      openedList === "Contacts" ? null : "Contacts"
                    )
                  }
                >
                  <i className="menu-icon mdi mdi-account-multiple" />
                  <span className="menu-title">All Contacts</span>
                  <i className="menu-arrow" />
                </div>
                <div
                  className={`collapse ${openedList === "Contacts" && "show"}`}
                  id="form-elements"
                >
                  <ul className="nav flex-column sub-menu">
                    {hasMultiplePermissions(loggedUser, [1, 2, 3, 4, 5]) && (
                      <li className={`nav-item`}>
                        <Link
                          className={`nav-link ${
                            activeLink === "contacts" && "active"
                          }`}
                          to="/contacts"
                          onClick={closeSidebar}
                        >
                          Contacts
                        </Link>
                      </li>
                    )}
                    {hasMultiplePermissions(loggedUser, [6, 7, 8, 9, 10]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "leads" && "active"
                          }`}
                          to="/leads"
                          onClick={closeSidebar}
                        >
                          Leads
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {hasMultiplePermissions(
              loggedUser,
              [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
            ) && (
              <li className="nav-item">
                <div
                  className={`nav-link ${
                    openedList === "Items" && "collapsed"
                  }`}
                  data-bs-toggle="collapse"
                  aria-expanded={openedList === "Items"}
                  aria-controls="form-elements"
                  onClick={() =>
                    handleOpenModel(openedList === "Items" ? null : "Items")
                  }
                >
                  <i className="menu-icon mdi mdi-cart-plus" />
                  <span className="menu-title">Items</span>
                  <i className="menu-arrow" />
                </div>
                <div
                  className={`collapse ${openedList === "Items" && "show"}`}
                  id="form-elements"
                >
                  <ul className="nav flex-column sub-menu">
                    {hasMultiplePermissions(
                      loggedUser,
                      [11, 12, 13, 14, 15]
                    ) && (
                      <li className="nav-item">
                        <Link
                          to="items"
                          className={`nav-link ${
                            activeLink === "items" && "active"
                          }`}
                          onClick={closeSidebar}
                        >
                          Item
                        </Link>
                      </li>
                    )}
                    {hasMultiplePermissions(loggedUser, [16, 17, 18]) && (
                      <li className="nav-item">
                        <Link
                          to="/categories"
                          className={`nav-link ${
                            activeLink === "categories" && "active"
                          }`}
                          onClick={closeSidebar}
                        >
                          Categories
                        </Link>
                      </li>
                    )}
                    {hasMultiplePermissions(loggedUser, [19, 20, 21]) && (
                      <li className="nav-item">
                        <Link
                          to="/brand"
                          className={`nav-link ${
                            activeLink === "brand" && "active"
                          }`}
                          onClick={closeSidebar}
                        >
                          Brand
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {hasMultiplePermissions(
              loggedUser,
              [22, 23, 24, 25, 26, 27, 28, 29, 30]
            ) && (
              <li className="nav-item">
                <div
                  className={`nav-link ${
                    openedList === "Taxes" && "collapsed"
                  }`}
                  data-bs-toggle="collapse"
                  aria-expanded={openedList === "Taxes"}
                  aria-controls="form-elements"
                  onClick={() =>
                    handleOpenModel(openedList === "Taxes" ? null : "Taxes")
                  }
                >
                  <i className="menu-icon mdi mdi-contrast" />
                  <span className="menu-title">Taxes</span>
                  <i className="menu-arrow" />
                </div>
                <div
                  className={`collapse ${openedList === "Taxes" && "show"}`}
                  id="form-elements"
                >
                  <ul className="nav flex-column sub-menu">
                    {hasMultiplePermissions(loggedUser, [22, 23, 24]) && (
                      <li className="nav-item">
                        <Link
                          to="tax"
                          className={`nav-link ${
                            activeLink === "tax" && "active"
                          }`}
                          onClick={closeSidebar}
                        >
                          Manage Tax
                        </Link>
                      </li>
                    )}

                    {hasMultiplePermissions(loggedUser, [25, 26, 27]) && (
                      <li className="nav-item">
                        <Link
                          to="/gsthsn"
                          className={`nav-link ${
                            activeLink === "gsthsn" && "active"
                          }`}
                          onClick={closeSidebar}
                        >
                          Manage HSN
                        </Link>
                      </li>
                    )}
                    {hasMultiplePermissions(loggedUser, [28, 29, 30]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "gstsac" && "active"
                          }`}
                          onClick={closeSidebar}
                          to="/gstsac"
                        >
                          Manage SAC
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}

            {hasMultiplePermissions(
              loggedUser,
              [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 55, 56, 57]
            ) && (
              <li className="nav-item">
                <div
                  className={`nav-link ${
                    openedList === "Invoices" && "collapsed"
                  }`}
                  data-bs-toggle="collapse"
                  aria-expanded={openedList === "Invoices"}
                  aria-controls="form-elements"
                  onClick={() =>
                    handleOpenModel(
                      openedList === "Invoices" ? null : "Invoices"
                    )
                  }
                >
                  <i className="menu-icon mdi mdi-card-text-outline" />
                  <span className="menu-title">Invoices</span>
                  <i className="menu-arrow" />
                </div>
                <div
                  className={`collapse ${openedList === "Invoices" && "show"}`}
                  id="form-elements"
                >
                  <ul className="nav flex-column sub-menu">
                    {hasMultiplePermissions(loggedUser, [31, 32, 33]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "sales" && "active"
                          }`}
                          onClick={closeSidebar}
                          to="/invoices/sales"
                        >
                          Sales
                        </Link>
                      </li>
                    )}
                    {hasMultiplePermissions(loggedUser, [34, 35, 36]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "expenses" && "active"
                          }`}
                          onClick={closeSidebar}
                          to="/invoices/expenses"
                        >
                          Expenses
                        </Link>
                      </li>
                    )}
                    {hasMultiplePermissions(loggedUser, [37, 38, 39]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "quotation" && "active"
                          }`}
                          onClick={closeSidebar}
                          to="/invoices/quotation"
                        >
                          Quotation
                        </Link>
                      </li>
                    )}
                    {/* <li className="nav-item">
                    <Link className="nav-link" to="/notes">
                      Debit/Credit Note
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/bill_of_supply">
                      Bill Of Supply
                    </Link>
                  </li> */}
                    {hasMultiplePermissions(loggedUser, [55, 56, 57]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "delivery_challan" && "active"
                          }`}
                          onClick={closeSidebar}
                          to="/invoices/delivery_challan"
                        >
                          Delivery Challan
                        </Link>
                      </li>
                    )}
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/bulk_payment">
                        Bulk Payment
                      </Link>
                    </li> */}
                    {hasMultiplePermissions(loggedUser, [40, 41, 42]) && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeLink === "payment_records" && "active"
                          }`}
                          onClick={closeSidebar}
                          to="/payment_records"
                        >
                          Payment Records
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}

            {hasPermission(loggedUser, 43) && (
              <li
                className={`nav-item ${activeLink === "summary" && "active"}`}
              >
                <Link className="nav-link" to="/summary" onClick={closeSidebar}>
                  <i className="menu-icon mdi mdi-chart-pie" />
                  <span className="menu-title">Reports</span>
                </Link>
              </li>
            )}

            {hasMultiplePermissions(loggedUser, [44, 45, 46]) && (
              <li className={`nav-item ${activeLink === "users" && "active"}`}>
                <Link className="nav-link" to="/users" onClick={closeSidebar}>
                  <i className="menu-icon mdi mdi-account-check" />
                  <span className="menu-title">Users</span>
                </Link>
              </li>
            )}
            <li className={`nav-item ${activeLink === "support" && "active"}`}>
              <Link className="nav-link" to="/support" onClick={closeSidebar}>
                <i className="menu-icon mdi mdi-alert-circle-outline" />
                <span className="menu-title">Support</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className="nav-link"
                data-bs-toggle="collapse"
                to="/settings"
                aria-expanded="false"
                aria-controls="ui-basic"
              >
                <i className="menu-icon mdi mdi-brightness-5" />
                <span className="menu-title">Settings</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => dispatch(setLogout())}
                to="/auth/login"
              >
                <i className="menu-icon mdi mdi-power" />
                <span className="menu-title">Logout</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Sidebar;
