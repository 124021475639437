import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userApi } from "../../assets/apis";
import toast from "react-hot-toast";
import Spinner from "../../spinner/first_spinner/Spinner";
import { userPermissionArray } from "./helper";
import "./AddUser.css";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import NotFoundWithImage from "../../notFound/NotFoundWithImage/NotFoundWithImage";

const EditUser = () => {
  const [addUserData, setAddUserData] = useState({});
  const [userPermissions, setUserPermissions] = useState([
    ...userPermissionArray,
  ]);
  const [selectedUserPermissions, setselectedUserPermissions] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.put(`${userApi}/${id}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("User saved successfully!");
      navigate("/users");
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    },
  });

  const handleChangeUserDataWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddUserData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAddUserData((pre) => ({ ...pre, [name]: value }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const { name, email, mobile, designation } = addUserData;
    if (!name || !name.trim()) return toast.error("Please enter Name");

    if (!mobile || mobile?.length < 10)
      return toast.error("Please enter a valid Mobile number");

    if (!selectedUserPermissions.length) {
      return toast.error("Please Select Atleast One User Permission");
    }

    let payload = {
      ...addUserData,
      ...(name && { name: name.trim() }),
      ...(email && { email: email.trim() }),
      ...(designation && { designation: designation.trim() }),
      user_permission: selectedUserPermissions,
    };
    mutation.mutate(payload);
  };

  const { data, isError, isLoading, error } = useQuery({
    queryKey: [id],
    queryFn: () =>
      axios.get(`${userApi}/${id}`).then((response) => response.data.data),
    retry: false,
  });

  useEffect(() => {
    if (data) {
      const {
        createdAt,
        updatedAt,
        companyId,
        resetPasswordExpire,
        resetPasswordToken,
        type,
        company_permission,
        id,
        ...rest
      } = data;
      setselectedUserPermissions(rest.user_permission);
      setUserPermissions((pre) =>
        pre.filter(
          (permission) =>
            !rest.user_permission.some(
              (selectedPermission) =>
                selectedPermission.value === permission.value
            )
        )
      );
      setAddUserData(rest);
    }
  }, [data]);

  const handleSelectUserPermission = (clickedOption) => {
    if (
      selectedUserPermissions.some(
        (selectedPermission) => selectedPermission.value === 0
      )
    ) {
      setUserPermissions((pre) => [
        selectedUserPermissions[0],
        ...pre.filter((permission) => permission.value !== clickedOption.value),
      ]);
      setselectedUserPermissions((pre) => [clickedOption]);
      return;
    }
    if (clickedOption.value === 0 && selectedUserPermissions.length) {
      setUserPermissions(
        userPermissionArray.filter((permission) => permission.value !== 0)
      );
      setselectedUserPermissions((pre) => [clickedOption]);
      return;
    }
    setUserPermissions((pre) =>
      pre.filter((permission) => permission.value !== clickedOption.value)
    );
    const indexToInsert = selectedUserPermissions.findIndex(
      (permission) => permission.value > clickedOption.value
    );
    if (indexToInsert === -1) {
      setselectedUserPermissions((pre) =>
        pre.filter((permission) => permission.value !== clickedOption.value)
      );
      setselectedUserPermissions((pre) => [...pre, clickedOption]);
    } else {
      setselectedUserPermissions((pre) => [
        ...pre.slice(0, indexToInsert),
        clickedOption,
        ...pre.slice(indexToInsert),
      ]);
    }
  };

  const handleSelectedUserPermissions = (clickedOption) => {
    setselectedUserPermissions((pre) =>
      pre.filter((permission) => permission.value !== clickedOption.value)
    );
    const indexToInsert = userPermissions.findIndex(
      (permission) => permission.value > clickedOption.value
    );
    if (indexToInsert === -1) {
      setUserPermissions((pre) =>
        pre.filter((permission) => permission.value !== clickedOption.value)
      );
      setUserPermissions((pre) => [...pre, clickedOption]);
    } else {
      setUserPermissions((pre) => [
        ...pre.slice(0, indexToInsert),
        clickedOption,
        ...pre.slice(indexToInsert),
      ]);
    }
  };

  if (isLoading) {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <SecondSpinner />
        </div>
      </div>
    );
  }

  if (isError) return <NotFoundWithImage />;

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Edit User</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/users")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-12 ">
                      <h5>USER INFORMATION</h5>
                    </div>
                    <form className="row" onSubmit={onSubmitHandler}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>User Name *</label>
                          <input
                            required
                            value={addUserData.name || ""}
                            onChange={(e) =>
                              handleChangeUserDataWithCondition(
                                e,
                                /^[a-zA-Z\s\b]+$/
                              )
                            }
                            name="name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Email *</label>
                          <input
                            required
                            value={addUserData.email || ""}
                            onChange={handleOnChange}
                            name="email"
                            type="email"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Mobile *</label>
                          <input
                            required
                            value={addUserData.mobile || ""}
                            name="mobile"
                            type="text"
                            className="form-control"
                            minLength={10}
                            maxLength={10}
                            onChange={(e) =>
                              handleChangeUserDataWithCondition(e, /^[0-9\b]+$/)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>designation</label>
                          <input
                            value={addUserData.designation || ""}
                            onChange={handleOnChange}
                            name="designation"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 row">
                        <div className="col-md-5 form-group">
                          <label>User Permissions</label>
                          <div className="select_list_container">
                            <ul className="value-list">
                              {userPermissions?.map((permission) => (
                                <li
                                  key={permission.value}
                                  onClick={() =>
                                    handleSelectUserPermission(permission)
                                  }
                                >
                                  {permission.label}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-2 arrow_container">
                          <div>
                            <i
                              id="add_permission"
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            <div>
                              <i
                                id="remove_permission"
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 form-group">
                          <label>Selected Permissions *</label>
                          <div className="select_list_container">
                            <ul className="value-list">
                              {selectedUserPermissions?.map((permission) => (
                                <li
                                  key={permission.value}
                                  className="selected-option"
                                  onClick={() =>
                                    handleSelectedUserPermissions(permission)
                                  }
                                >
                                  {permission.label}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-primary d-flex justify-content-center px-3 py-2"
                        >
                          <i className="mdi mdi-content-save me-1"></i> Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
